import {useSearchParams} from "react-router-dom"


function formatQuery(obj) {
    let res
    let i =0
    for (const key in obj){
        res += `${i === 0 ? "?" : "&"}${key}=${obj[key]}`
        i++;
    }
    return res
}

async function Page(a) {
    const [params, setParams] = useSearchParams()
    let urlData = {
        code: params.get('code'),
        grant_type: 'authorization_code',
        client_id: '5180889471712067319',
        client_secret: "RBX-YqMusMtsCEaa5JwJsgSrrUWadgz_Ctr6VD698KthiLTNkw9vkqG6gl4ci592C7iT"
    }
    let request = await fetch(`https://apis.roblox.com/oauth/v1/token${formatQuery(urlData)}`, {
        method: "post",
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
    if (request.ok){
        let j = await request.json()
        console.log(j)
    }
    return (
        <p>hi</p>
    )
}

export default Page