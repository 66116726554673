import { Outlet } from "react-router-dom";

import './App.css';


function App() {
  return (
    <>
      <div className='app'>

      </div>
      <Outlet />
    </>
  );
}

export default App;
