import {useSearchParams} from "react-router-dom"

function Page(a,b) {
    const [params, setParams] = useSearchParams()
    console.log(params.get('oh'))
    return (
        <p>home</p>
    )
}

export default Page