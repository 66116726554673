import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter , Routes, Route } from "react-router-dom";
import './index.css';

import App from './App';
import Home from './pages/Home'
import Dev from "./pages/Dev"
import Redirect from "./pages/Redirect"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<App/>}>
            <Route index element={<Home/>}/>
            <Route path='dev/:id' element={<Dev/>}/>
            <Route path='redirect/' element={<Redirect/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

/*
 https://apis.roblox.com/oauth/v1/authorize?client_id=5180889471712067319&redirect_uri=https://roverify.pages.dev/redirect&scope=openid profile&response_type=code
 */