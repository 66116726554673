import {useParams} from "react-router-dom"


function Page(a) {
    const {id} = useParams()
    return (
        <p>{id}</p>
    )
}

export default Page